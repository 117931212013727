<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Change Password
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="changePasswordForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Current Password*"
                label-for="h-user-current-password"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Current Password"
                  vid="current-password"
                  rules="required|min:6"
                >
                  <b-form-input
                    id="h-user-current-password"
                    v-model="currentPassword"
                    placeholder="Current Password"
                    type="password"
                    :state="(errors.length > 0 || currentPasswordValidation) ? false : null"
                    name="currentPassword"
                    @input="currentPasswordValidation == true ? currentPasswordValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="currentPasswordValidation"
                    class="text-danger"
                  >
                    {{ currentPasswordError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-button
                variant="flat-dark"
                class="no-bg-focus"
                @click="showMessage"
              >
                Forget Password?
              </b-button>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="New Password*"
                label-for="h-user-new-password"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  vid="newPassword"
                  rules="required|min:6"
                >
                  <b-form-input
                    id="h-user-new-password"
                    ref="newPassword"
                    v-model="newPassword"
                    placeholder="New Password"
                    type="password"
                    :state="(errors.length > 0 || newPasswordValidation) ? false : null"
                    name="newPassword"
                    @input="newPasswordValidation == true ? newPasswordValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="newPasswordValidation"
                    class="text-danger"
                  >
                    {{ newPasswordError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Confirm New Password*"
                label-for="h-user-confirm-password"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  vid="confirm-password"
                  rules="required|min:6|confirmed:newPassword"
                >
                  <b-form-input
                    id="h-user-confirm-password"
                    v-model="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    :state="(errors.length > 0 || confirmPasswordValidation) ? false : null"
                    name="confirmPassword"
                    @input="confirmPasswordValidation == true ? confirmPasswordValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="confirmPasswordValidation"
                    class="text-danger"
                  >
                    {{ confirmPasswordError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'directory-users-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Password</span>
              </b-button>
            </li>
          </b-nav>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      currentPassword: '',
      currentPasswordError: 'Valid Password is required',
      currentPasswordValidation: false,
      newPassword: '',
      newPasswordError: 'Valid Password is required',
      newPasswordValidation: false,
      confirmPassword: '',
      confirmPasswordError: 'Valid Password is required',
      confirmPasswordValidation: false,

      // validation rules
      required,
      min,
    }
  },
  methods: {
    showMessage() {
      this.$swal({
        title: 'Forget Password?',
        text: 'Please contact your administrator if you forget your password. ',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
        cancelButtonText: '',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('currentPassword', this.currentPassword)
          formData.append('newPassword', this.newPassword)
          formData.append('confirmPassword', this.confirmPassword)

          this.$http.patch(`directory/users/${this.user._id}/update/password/self`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$swal({
                title: 'New Password Saved!',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.$router.push({ name: 'directory-my-profile' })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'confirmPassword') {
                    this.confirmPasswordError = validationError.msg
                    this.confirmPasswordValidation = true
                  } else if (validationError.param === 'currentPassword') {
                    this.currentPasswordError = validationError.msg
                    this.currentPasswordValidation = true
                  } else if (validationError.param === 'newPassword') {
                    this.newPasswordError = validationError.msg
                    this.newPasswordValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style>
  .no-bg-focus:focus {
    background-color: transparent !important;
  }
</style>
